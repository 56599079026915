import React from "react";
import { Container } from "react-bootstrap";
import Book from "../../../../images/ContentOffer/ContentOffer.png";
import { Star, LogoAmazon, LogoApple } from "react-ionicons";
import * as s from "./index.module.scss";
import { PrismicRichText } from "@prismicio/react";

const ContentOffer = ({ slice }) => {
  return (
    <section className={`section-center ${s.content_offer}`}>
      <Container>
        <div className={s.content_offer_container}>
          <div className={s.title}>
            <PrismicRichText field={slice?.primary?.high_value_offer_title} />
          </div>
          <article className={s.image_container}>
            <img src={slice?.primary?.high_value_offer_image?.url} alt="" />
          </article>
          <article>
            <div className={s.content_description}>
              <h2>
                <PrismicRichText
                  field={slice?.primary?.high_value_offer_title}
                />
              </h2>
              <PrismicRichText field={slice?.primary?.high_value_offer_text} />
            </div>
            <div className={s.ratings}>
              <div>
                <Star />
                <Star />
                <Star />
                <Star />
                <Star />
              </div>
              <p>4.8 stars out of 3,415 reviews</p>
            </div>
            <div className={s.button_container}>
              <a href="" className={`btn btn-primary ${s.buy_book}`}>
                {slice?.primary?.high_value_offer_buy_button_text}
              </a>
              <div>
                <a href="" className="btn btn-outline-primary">
                  <span>
                    <LogoAmazon />
                  </span>{" "}
                  {slice?.primary?.high_value_offer_amazon_button_text}
                </a>
                {/* <a href="" className="btn btn-outline-primary">
                                <span><LogoApple/></span> Apple
                            </a> */}
              </div>
            </div>
          </article>
        </div>
      </Container>
    </section>
  );
};

export default ContentOffer;
