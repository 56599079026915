import React from 'react'
import { Container, Tab, Tabs, Sonnet } from 'react-bootstrap'
import * as s from './index.module.scss'
import { PrismicRichText } from '@prismicio/react'
import data from './utils/data'

const Journey = ({slice}) => {
  return (
    <section className={`section-center ${s.journey}`} id="journey">
      <Container>
        <PrismicRichText field={slice?.primary?.choose_your_journey}/>

        <Tabs
          defaultActiveKey="forYou"
          id="uncontrolled-tab-example"
          className="tabs-journey"
        >
          <Tab eventKey="forYou" title="For You">
            <div className={s.tab_grid}>
              {
                slice?.items?.map(({for_organization, for_you_point_icon, journey_point_image, journey_point_text}, idx)=>{
                  if(!for_organization){
                    return <div className={`${s.single_tab_item}`} key={idx}>
                      <img src={journey_point_image?.url} alt="" className={s.background_image}/>
                      <div className={s.overlay}>
                        <img src={for_you_point_icon?.url} alt="" />
                        <p>
                          {journey_point_text}
                        </p>
                      </div>
                    </div>
                  }  
                })
              }
            </div>
          </Tab>
          <Tab eventKey="forOrganizations" title="For Organizations">
            <div className={s.tab_grid}>
              {
                slice?.items?.map(({for_organization, for_you_point_icon, journey_point_image, journey_point_text}, idx)=>{
                  if(for_organization){
                    return <div className={`${s.single_tab_item}`} key={idx}>
                      <img src={journey_point_image?.url} alt="" className={s.background_image}/>
                      <div className={s.overlay}>
                        <img src={for_you_point_icon?.url} alt="" />
                        <p>
                          {journey_point_text}
                        </p>
                      </div>
                    </div>
                  }  
                })
              }
            </div>
          </Tab>
        </Tabs>
      </Container>
    </section>
  )
}

export default Journey