import React, { useState } from "react";
import {
  Container,
  Navbar,
  Offcanvas,
  Nav,
  NavDropdown,
} from "react-bootstrap";
import { Link } from "gatsby";
import { CallOutline, SearchOutline, MenuOutline } from "react-ionicons";
import * as s from '../index.module.scss'
// import Logo from '../../../images/Logo.svg'
import Logo from '../../../images/Logo.png'
import {links} from '../utils/data'

const expand = "md";

const NavigationBar = () => {
  const [expanded, setExpanded] = useState(false);
  const closeMenu = () => setExpanded(false);
  const scroll = (e)=>{
    e.preventDefault();
    const id = e.currentTarget.getAttribute('href').slice(1).replace(/[^a-zA-Z ]/g, "");
    const element = document.getElementById(id);
    const navHeight = document.querySelector('.navigation-bar').getBoundingClientRect().height;
    // const linksHeight = document.querySelector('.links-container').getBoundingClientRect().height;
    let position = element.offsetTop - navHeight;
    if(navHeight > 130){
        position += 80;
    }
    window.scrollTo({
        top: position,
        left:0,
    });
    console.log(id, element, navHeight);
    setExpanded(false);
}

  return (
    <Navbar
    expand={expand}
    className={`${s.navbar} flex-wrap navigation-bar`}
    collapseOnSelect={true}
    expanded={expanded}
    >
    <Container className="flex-wrap">
        <div className={`${s.nav_container} d-flex justify-content-between align-items-center w-100`}>
            <Navbar.Brand as="div">
                <Link to={"/"}>
                <img src={Logo} alt="" className={s.logo}/>
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}  
                onClick={() => setExpanded(true)}
                className={s.toggle}
            >
                <MenuOutline/>
            </Navbar.Toggle> 
            <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                onHide={closeMenu}
            >
                <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    Menu
                </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                <Nav className={`justify-content-end flex-grow-1 ${s.nav_links}`} as={"ul"}>
                    {
                    links.map(({label, link}, idx)=>{
                        return <Nav.Link as="li" key={idx}>
                            <Link to={link} onClick={scroll}>
                                {label}
                            </Link>
                        </Nav.Link>
                    })
                    }
                </Nav>
                </Offcanvas.Body>
            </Navbar.Offcanvas>
        </div>
    </Container>
    </Navbar>
  );
};

export default NavigationBar;
