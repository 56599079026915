import React, { useState, useEffect } from "react";
// import Logo from '../../../images/Logo.svg'
import Logo from "../../../images/Logo.png";
import { Container } from "react-bootstrap";
import { footerLinks } from "../utils/data";
import { Link } from "gatsby";
import * as s from "../index.module.scss";

const Footer = () => {
  const [year, setYear] = useState("");

  useEffect(() => {
    const currYear = new Date();
    setYear(currYear.getFullYear());
  }, []);

  return (
    <footer className={s.footer}>
      <Container>
        <article className="d-md-flex align-items-center justify-content-between">
          <div className={s.logo_container}>
            <img src={Logo} alt="" className={s.footer_logo} />
            <p className={`mb-0 text-center signature ${s.tagline}`}>
              Be@YourBest
            </p>
          </div>
          <div className={s.footer_links_container}>
            {footerLinks.map(({ title, links }, idx) => {
              return (
                <ul key={idx}>
                  {title}
                  {links.map(({ link, label }, idx) => {
                    return (
                      <li key={idx}>
                        <Link to={link} key={idx}>
                          {label}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              );
            })}
          </div>
        </article>
        <div className={s.divider}></div>
        <article className={s.terms_and_conditions}>
          <Link to={""}>Terms and conditions</Link>
          <div>©{year} - Global Leadership Foundry | All right reserved</div>
        </article>
      </Container>
    </footer>
  );
};

export default Footer;
