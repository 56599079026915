import React, {useRef, useEffect, useState} from "react";
import Hero from '../../../../images/Hero/Hero.png'
import { Container } from "react-bootstrap";
import { Play, HeadsetOutline } from "react-ionicons";
import data from './utils/data'
import { PrismicRichText } from "@prismicio/react";
import * as s from './index.module.scss'

const HeroSection = ({ hero }) => {
  console.log(hero);
  const hoverText = useRef(null);

  const [play, setPlay] = useState(false);
  const video = useRef(null)

  const handlePlay = () => {
      setPlay(true)
      video.current.play();
  }

  useEffect(()=>{
    const spans = hoverText.current.querySelectorAll('.span')

    hoverText.current.addEventListener('mouseout', (e)=>{
      spans[0].previousElementSibling.style.opacity = 1;
      spans[0].style.borderBottom = '3px dashed black';
    })

    spans.forEach((span)=>{
      span.addEventListener('mouseover', (e)=>{
        spans.forEach((el)=>{
          el.previousElementSibling.style.opacity = 0;
          el.style.borderBottom = 'none';
        })
        e.target.previousElementSibling.style.opacity = 1; 
        e.target.style.borderBottom = '3px dashed black';
      })
      span.addEventListener('mouseout', (e)=>{
        e.target.previousElementSibling.style.opacity = 0; 
        e.target.style.borderBottom = 'none';
      })
    })
    return()=>{

      hoverText.current.removeEventListener('mouseout', (e)=>{
        spans[0].previousElementSibling.style.opacity = 1;
        spans[0].style.borderBottom = '3px dashed black';
      })

      spans.forEach((span)=>{
        span.addEventListener('mouseover', (e)=>{
          e.target.style.borderBottom = '3px dashed black';
        })
        span.addEventListener('mouseout', (e)=>{
          e.target.style.borderBottom = 'none';
        })
      })
    }
  },[])

  return <section className={s.hero} id="home">
    <article className={s.hero_video}>
      <video ref={video} poster={hero?.hero_video_thumbnail?.url} controls={play}>
          <source src={hero?.hero_video_link?.url} type="video/mp4"/>
      </video>
      <span className={`${s.icon} ${play? 'd-none' : ''}`} onClick={handlePlay}>
        <Play/>
      </span>
    </article>
    <article className={s.hero_content}>
      <h1 ref={hoverText}>
        {
          data.map(({text, hoverText, hoverImage}, idx)=>{
            return <span key={idx} className={`${s.hover_text}`}>
              <img src={hoverImage} alt="" />
              <span className="span">{text}</span>
              <p className="signature">{hoverText}</p>
            </span>
          })
        }
      </h1>
      <PrismicRichText field={hero?.hero_description}/>
      <div className={s.button_container}>
        <button className="btn btn-primary">
          {hero?.hero_button_text}
        </button>
        <a href="" className="btn btn-outline-primary d-inline-flex align-items-center justify-content-center">
          <span> <HeadsetOutline/> </span> {hero?.hero_contact_button_text}
        </a>
      </div>
    </article>
  </section>;
};

export default HeroSection;
