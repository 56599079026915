import React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { PrismicRichText, SliceZone } from "@prismicio/react";
import Metadata from "../../components/Metadata";
import HeroSection from "./components/Hero";
import OurPurpose from "./components/Purpose";
import Journey from "./components/Journey";
import ClientSuccess from "./components/ClientSuccess";
import Team from "./components/Team";
import ContentOffer from "./components/ContentOffer";
import Clients from "./components/Clients";
import Partners from "./components/Partners";
import Faq from "./components/Faq";
import Newsletter from "./components/Newsletter";
import "../../styles/globals.scss";
import Metaverse from "./components/Metaverse";

const HomePageTemplate = ({ data }) => {
  const pageData = data?.prismicHomePage.dataRaw;
  console.log(pageData);

  const hero = {
    hero_video_thumbnail: pageData?.hero_video_thumbnail || {},
    hero_video_link: pageData?.hero_video_link || {},
    hero_description: pageData?.hero_description || [],
    hero_button_text: pageData?.hero_button_text || "",
    hero_contact_button_text: pageData?.hero_contact_button_text || "",
  };

  const components = {
    our_purpose: OurPurpose,
    choose_your_journey: Journey,
    client_success: ClientSuccess,
    high_value_offer: ContentOffer,
    clients: Clients,
    partners: Partners,
    faqs: Faq,
    team: Team,
    newsletter: Newsletter,
    metaverse: Metaverse,
  };

  return (
    <>
      <HeroSection hero={hero} />
      <SliceZone slices={pageData?.body} components={components} />
    </>
  );
};

export default withPrismicPreview(HomePageTemplate);

export const Head = ({ data }) => {
  return <Metadata pageData={data?.prismicHomePage?.dataRaw} />;
};

export const query = graphql`
  query homePageQuery($id: String) {
    prismicHomePage(id: { eq: $id }) {
      _previewable
      dataRaw
    }
  }
`;
