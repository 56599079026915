import React from 'react'
import { Container, Accordion } from 'react-bootstrap'
import { PrismicRichText } from '@prismicio/react'
import data from './utils/data'
import * as s from './index.module.scss'

const Faq = ({slice}) => {
  return (
    <section className={`section-center ${s.faq}`}>
        <Container>
            <article className={s.faq_header}>
                <PrismicRichText field={slice?.primary?.faqs_title}/>
                <PrismicRichText field={slice?.primary?.faqs_text}/>
            </article>
            <article>
                <Accordion>
                    {
                        slice?.items?.map(({faqs_question, faqs_answer}, idx)=>{
                            return <Accordion.Item eventKey={idx} key={idx}>
                                <Accordion.Header>{faqs_question}</Accordion.Header>
                                <Accordion.Body><PrismicRichText field={faqs_answer}/></Accordion.Body>
                            </Accordion.Item>
                        })
                    }
                </Accordion>
            </article>
        </Container>
    </section>
  )
}

export default Faq