import Youtube from '../../../images/youtube.svg'
import Facebook from '../../../images/facebook.svg'
import Instagram from '../../../images/instagram.svg'

const socials = [
    {
        icon: Facebook,
        link: ""
    },
    {
        icon: Youtube,
        link: ""
    },
    {
        icon: Instagram,
        link: ""
    },
]

const links = [
    {
        link: "/home",
        label: "Home"
    },
    {
        link: "/purpose",
        label: "Our Purpose"
    },
    {
        link: "/who-we-are",
        label: "Who We Are"
    },
    {
        link: "/journey",
        label: "For You"
    },
    {
        link: "/journey",
        label: "For Organizations"
    }
]

const footerLinks = [
    {
        title: "Quick Links",
        links: [
            {
                link: "/",
                label: "Our Purpose"
            },
            {
                link: "",
                label: "For You"
            },
            {
                link: "",
                label: "For Organization"
            },
            {
                link: "",
                label: "What our client say"
            }
        ]
    },
    {
        title: "Quick Links",
        links: [
            {
                link: "/",
                label: "Who we are"
            },
            {
                link: "",
                label: "Partner Universities"
            },
            {
                link: "",
                label: "Partner Organizations"
            },
            {
                link: "",
                label: "Resources for you"
            }
        ]
    },
    {
        title: "Other",
        links: [
            {
                link: "/",
                label: "FAQs"
            },
            {
                link: "",
                label: "Contact us"
            },
            {
                link: "",
                label: "About us"
            },
            {
                link: "",
                label: "Privacy policy"
            }
        ]
    }
]

const speacialLinkOne = {
    title: "",
    links: [
        {
            link: "/",
            label: "Our Purpose"
        },
        {
            link: "",
            label: "For You"
        },
        {
            link: "",
            label: "For Organization"
        },
        {
            link: "",
            label: "What our client say"
        }
    ]
}

const speacialLinkTwo = {
    title: "",
    links: [
        {
            link: "/",
            label: "Who we are"
        },
        {
            link: "",
            label: "Partner Universities"
        },
        {
            link: "",
            label: "Partner Organizations"
        },
        {
            link: "",
            label: "Resources for you"
        }
    ]
}

const other = {
    title: "",
    links: [
        {
            link: "/",
            label: "FAQs"
        },
        {
            link: "",
            label: "Contact us"
        },
        {
            link: "",
            label: "About us"
        },
        {
            link: "",
            label: "Privacy policy"
        }
    ]
}

export {socials, links, speacialLinkOne, speacialLinkTwo, other, footerLinks}