import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { CheckmarkCircle } from "react-ionicons";
import MetaverseImage from "../../../../images/Metaverse/Metaverse.png";
import * as s from "./index.module.scss";
import data from "./utils/data";
import { PrismicRichText } from "@prismicio/react";

const Metaverse = ({ slice }) => {
  console.log("Meta Slice: ", slice);
  return (
    <section className={`section-center ${s.metaverse}`}>
      <Container>
        <h2>{slice?.primary?.title}</h2>
        <h3>{slice?.primary?.subtitle}</h3>
        <Row>
          <Col className={`d-none d-lg-block ${s.metaverse_image}`} lg={6}>
            <img src={MetaverseImage} alt="" />
          </Col>
          <Col lg={6}>
            <div className={s.points_container}>
              {slice?.items?.map(({ meta_point }, idx) => {
                return (
                  <div
                    className={`${s.single_point} d-flex align-items-start justify-content-center justify-content-lg-start`}
                    key={idx}
                  >
                    <div>
                      <CheckmarkCircle />
                    </div>
                    <PrismicRichText field={meta_point} />
                  </div>
                );
              })}
            </div>
            <a
              href={slice?.primary?.button_link?.url}
              className="btn btn-primary w-100"
              target="_blank"
            >
              {slice?.primary?.button_text}
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Metaverse;
