import React, { useState } from "react";
import Navbar from './Navbar'
import Info from './Info'
import Footer from "./Footer";
import * as s from './index.module.scss'

const Layout = ({ children }) => {
  return (
    <div>
      {/* <Info/> */}
      {/* <div className={s.divider}></div> */}
      <Navbar/>
      {children}
      <Footer/>
    </div>
  );
};

export default Layout;
