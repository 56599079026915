import React, {useState, useEffect} from 'react'
import * as s from './index.module.scss'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { PrismicRichText } from '@prismicio/react';
import "swiper/css";
import {clientsOne, clientsTwo} from './utils/data'

const Clients = ({slice}) => {
    const [itemsPerSlider, setItemsPerSlider] = useState(0);
    useEffect(()=>{
        const noOfItems = slice?.items?.length || 0;
        console.log("NO ", noOfItems/2);
        setItemsPerSlider(noOfItems/2)
    }, [])
  return (
    <section className={`section-center ${s.clients}`}>
        <PrismicRichText field={slice?.primary?.clients_title}/>
        <div className={s.swiper_container}>
            <Swiper
                slidesPerView={1.7}                
                spaceBetween={30}
                autoplay={{
                  delay: 0,
                  disableOnInteraction: false,
                }}
                speed={5400}
                loop={true}
                modules={[Autoplay]}
                breakpoints={
                    {
                        768:{
                            slidesPerView: 3.4
                        },   
                        1024:{
                            slidesPerView: 4.3
                        },   
                        1440:{
                            slidesPerView: 6,
                        },   
                    }
                }
            >
                {
                    slice?.items?.map(({client_logo}, idx)=>{
                        if (idx >= itemsPerSlider) return null
                        return <SwiperSlide key={idx}>
                            <img src={client_logo?.url} alt="" />
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </div>
        <div className={s.swiper_container}>
            <Swiper
                slidesPerView={1.7}                
                spaceBetween={30}
                autoplay={{
                  delay: 0,
                  disableOnInteraction: false,
                }}
                speed={5000}
                loop={true}
                modules={[Autoplay]}
                breakpoints={
                    {
                        768:{
                            slidesPerView: 3.4
                        },   
                        1024:{
                            slidesPerView: 4.3
                        },   
                        1440:{
                            slidesPerView: 6,
                            loop: false
                        },   
                    }
                }
            >
                {
                    slice?.items?.map(({client_logo}, idx)=>{
                        if (idx < itemsPerSlider) return null
                        return <SwiperSlide key={idx}>
                            <img src={client_logo?.url} alt="" />
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </div>
    </section>
  )
}

export default Clients