import React from 'react'
import { Container } from 'react-bootstrap'
import { PrismicRichText } from '@prismicio/react';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import * as s from './index.module.scss'
import { ArrowForwardOutline } from 'react-ionicons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from "swiper";
import data from './utils/data'
import quotation from '../../../../images/quotation.svg' 

const ClientSuccess = ({slice}) => {
  return (
    <section className={`section-center ${s.client_success}`}>
        <Container>
            <PrismicRichText field={slice?.primary?.client_success_title}/>
            <div>
                <Swiper
                    slidesPerView={1}
                    pagination={true} 
                    autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                    }}
                    speed={3000}
                    navigation={true}
                    modules={[Pagination, Navigation, Autoplay]}
                >
                    {
                        slice?.items?.map(({client_success_image, client_success_name, client_success_position, client_success_testimony}, idx)=>{
                            return <SwiperSlide key={idx} >
                                <div className={s.single_testimony}>
                                    <div>
                                        <img src={client_success_image.url} alt="" />
                                    </div>
                                    <div className='d-md-flex justify-content-start align-items-center'>
                                        <div className='d-md-flex justify-content-start align-items-start'>
                                            <div className={s.quotation}>
                                                <img src={quotation} alt="" />
                                            </div>
                                            <div>
                                                <PrismicRichText field={client_success_testimony}/>
                                                <div className={s.client}>
                                                    <p>{client_success_name}</p>
                                                    <p>{client_success_position}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        })
                    }
                </Swiper>
            </div>
            <PrismicRichText field={slice?.primary?.client_success_cta_title}/>
            <button className="btn btn-primary">
                {slice?.primary?.client_success_button_text}
                <ArrowForwardOutline/>
            </button>
        </Container>
    </section>
  )
}

export default ClientSuccess