import React from 'react'
import { Container } from 'react-bootstrap'
import { PrismicRichText } from '@prismicio/react'
import DinoshiK from '../../../../images/Markus&Dinoshi/Dinoshi.png'
import * as s from './index.module.scss'

const Dinoshi = ({slice}) => {
  return (
    <section className={s.dinoshi}>
        <article className={s.dinoshi_image_container}>
            <img src={slice?.primary?.dinoshi_image?.url} alt="" />
        </article>
        <article className={s.dinoshi_text_container}>
            <PrismicRichText field={slice?.primary?.dinoshi_text}/>
            <div className={s.signature_container}>
                <p className="signature">
                    Dinoshi Keiper
                </p>
                <PrismicRichText field={slice?.primary?.dinoshi_name}/>
                <p>
                    {slice?.primary?.dinoshi_position}
                </p>
            </div>
        </article>
    </section>
  )
}

export default Dinoshi