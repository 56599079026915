import React from 'react'
import * as s from './index.module.scss'
import { Container } from 'react-bootstrap'
import data from './utils/data'
import { PrismicRichText } from '@prismicio/react'

const OurPurpose = ({slice}) => {
  return (
    <section className={`section-center ${s.purpose}`} id="purpose">
        <Container>
            <PrismicRichText field={slice?.primary?.our_purpose_title || []}/>
            <PrismicRichText field={slice?.primary?.our_purpose_description || []}/>
            <div className={s.purpose_grid}>
                {
                    slice?.items?.map(({purpose_point_icon, purpose_point_title, purpose_point_description, purpose_point_image}, idx)=>{
                        return <div className={s.grid_item} key={idx}>
                            <div className={s.content}>
                                <img src={purpose_point_icon?.url} alt="" className={s.grid_item_icon}/>
                                <p>{purpose_point_title}</p>
                                <PrismicRichText field={purpose_point_description || []}/>
                            </div>
                            <div className={s.overlay}>
                                <img src={purpose_point_image?.url} alt="" />
                            </div>
                            <div className={s.overlay_color}>
                            </div>
                        </div>
                    })
                }
            </div>
        </Container>
    </section>
  )
}

export default OurPurpose