import React from 'react'
import { PrismicRichText } from '@prismicio/react';
import * as s from './index.module.scss'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import partners from './utils/data'

const Partners = ({slice}) => {
  return (
    <section className={`${s.partners}`}>
        <PrismicRichText field={slice?.primary?.partners_title}/>
        <div className={s.swiper_container}>
            <Swiper
                slidesPerView={1.7}                
                spaceBetween={30}
                autoplay={{
                  delay: 0,
                  disableOnInteraction: false,
                }}
                speed={5400}
                loop={true}
                modules={[Autoplay]}
                breakpoints={
                    {
                        768:{
                            slidesPerView: 3.4
                        },   
                        1024:{
                            slidesPerView: 4.3
                        },   
                        1440:{
                            slidesPerView: 6,
                        },   
                    }
                }
            >
                {
                    slice?.items?.map(({partners_logo}, idx)=>{
                        return <SwiperSlide key={idx}>
                            <img src={partners_logo?.url} alt="" />
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </div>
    </section>
  )
}

export default Partners