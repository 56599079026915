import React from 'react'
import { Container } from 'react-bootstrap'
import { PrismicRichText } from '@prismicio/react'
import Markus from '../Markus'
import Dinoshi from '../Dinoshi'
import * as s from './index.module.scss'

const Team = ({slice}) => {
  return (
    <section className={s.team} id="whoweare">
        <Container>
            <PrismicRichText field={slice?.primary?.team_title}/>
        </Container>
        <Markus
            slice={slice}
        />
        <Dinoshi
            slice={slice}
        />
    </section>
  )
}

export default Team