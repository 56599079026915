import Be from '../../../../../images/Hero/Be.svg'
import At from '../../../../../images/Hero/At.svg'
import Your from '../../../../../images/Hero/Your.svg'
import Best from '../../../../../images/Hero/Best.svg'
import Plus from '../../../../../images/Hero/+\ Plus.svg'

const data = [
    {
        text: "Be",
        hoverText: "Be Yourself",
        hoverImage: Be
    },
    {
        text: "@",
        hoverText: "at The next level",
        hoverImage: At
    },
    {
        text: "Your",
        hoverText: "Design your future",
        hoverImage: Your
    },
    {
        text: "Best",
        hoverText: "Learn from the best ",
        hoverImage: Best
    },
    {
        text: " + Plus",
        hoverText: "Embrace new knowledge & technologies",
        hoverImage: Plus
    },
]

export default data;