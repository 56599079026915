import React from 'react'
import { Container, FloatingLabel, Form } from 'react-bootstrap'
import * as s from './index.module.scss'
import { PrismicRichText } from '@prismicio/react'

const Newsletter = ({slice}) => {
  return (
    <section>
        <Container>
            <div className={s.newsletter_container}>
                <PrismicRichText field={slice?.primary?.newsletter_title}/>
                <Form>
                    <PrismicRichText field={slice?.primary?.newsletter_text}/>
                    <div className='d-flex align-items-center w-100 mb-3'>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Your Email"
                            className='w-100'
                        >
                            <Form.Control type="email" placeholder="Your Email" required/>
                        </FloatingLabel>
                        <button type="submit" className='btn btn-primary'>Sign up</button>
                    </div>
                    <div className='mb-3'>
                        <Form.Check
                            inline
                            label="Receive free inspiration and Be@YourBest updates"
                            name="group"
                            type={"checkbox"}
                            id={1}
                        />
                    </div>
                    <div>
                        <Form.Check
                            inline
                            label="Be the first to know about new programs, videos, fireside chats, exclusive offers"
                            name="group"
                            type={"checkbox"}
                            id={2}
                        />
                    </div>
                </Form>
            </div>
        </Container>
    </section>
  )
}

export default Newsletter