import React from 'react'
import MarkusImage from '../../../../images/Markus&Dinoshi/Markus.png'
import { PrismicRichText } from '@prismicio/react'
import * as s from './index.module.scss'
import { Container } from 'react-bootstrap'

const Markus = ({slice}) => {
  return (
    <div className={s.team_content}>
        <Container>
            <div className={s.team_grid}>
                <article className={s.image_container}>
                    <img src={slice?.primary?.markus_image?.url} alt="" />
                </article>
                <article className={s.text_container}>
                    <PrismicRichText field={slice?.primary?.markus_text}/>
                    <div className={s.signature_container}>
                        <p className='signature'>Markus L. Keiper</p>
                        <h3>
                            {slice?.primary?.markus_name}
                        </h3>
                        <p>
                            {slice?.primary?.markus_position}
                        </p>
                    </div>
                </article>
            </div>
        </Container>
    </div>
  )
}

export default Markus